<template>
  <div id="vm-cola-shopping-bag" class="vm-cola-shopping-bag">
    <div class="shopping-container">
      <!-- <h1>SCAN TO PAY</h1>
      <div class="qrcode">
        <qrcode-vue :value="QRcode" :size="175" level="H"></qrcode-vue>
      </div> -->

      <!-- <div class="shopping-total">
        <div class="total-container">
          <span>Total Price</span>
          <span>{{ total_price | currencyRp }}</span>
        </div>
      </div> -->

      <div class="product-quantities">
        <span>Pesananmu:</span>
        <span class="qty">{{ cart_qty_total }} produk</span>
      </div>

      <table class="shopping-table">
        <tr v-for="(item, i) in carts" :key="i">
          <td>
            <figure>
              <img :src="pickImage(item.raw.detail)" :alt="item.name" />
            </figure>
          </td>
          <td>
            <h4>{{ item.brand }}</h4>
            <h5 class="name">{{ item.name }}</h5>
            <h5 class="combination" v-html="setAttributeName(item)"></h5>
            <h5 class="price" v-html="convertPrice(item.raw.detail)"></h5>
          </td>

          <td>
            <div class="shopping-table__action">
              <div class="qty">
                <button class="minus" @click.prevent="adjustQty(i, 'minus')">
                  <img src="/static/img/icons/icon-minus-borderless-pink.svg" alt="Minus Icon" />
                </button>
                <input type="number" :value="item.qty" readonly />
                <button class="plus" @click.prevent="adjustQty(i, 'plus')">
                  <img src="/static/img/icons/icon-plus-borderless-pink.svg" alt="Plus Icon" />
                </button>
              </div>
              <button class="remove" @click.prevent="confirmRemoveCart(i)">
                <img src="/static/img/icons/icon-delete-pink.svg" alt="Remove Product Shopping Bag" />
              </button>
            </div>
          </td>
        </tr>
      </table>

      <div v-if="!is_guest" class="redeem-points">
        <span class="title"> Redeem Your SOCO Points </span>
        <div
          class="point-wrapper"
          :class="{ disabled: user_points <= 100 || total_price < 20000 }"
          @click="eligible_redeem_soco_point ? showRedeemPointList() : null"
        >
          <div class="point-wrapper__detail" :class="{ point_selected: selected_point }">
            <template v-if="point_applied">
              <span class="title"
                >Kamu mendapatkan diskon <b>{{ (selected_point * 100) | currencyRp }}</b></span
              >
            </template>
            <template v-else>
              <span class="title">Kamu memiliki</span>
              <img
                src="/static/img/icons/icon-soco-points.svg"
                alt="Icon SP"
                @click="eligible_redeem_soco_point ? showRedeemPointList() : null"
              />
              <span class="point">{{ user_points | thousandSeparator }}</span>
            </template>
          </div>

          <template v-if="point_applied">
            <div class="point-wrapper__action" @click.stop.prevent="confirmCancelPoint">
              <img src="/static/img/icons/icon-close-pink.svg" alt="Icon Close Pink" />
            </div>
          </template>
          <template v-else>
            <div
              class="point-wrapper__action"
              @click.stop.prevent="eligible_redeem_soco_point ? showRedeemPointList() : null"
            >
              <img src="/static/img/icons/red-down-arrow.svg" alt="Icon Down" />
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- <div class="place-order" :class="{ disabled: !cart_qty_total || cart_qty_total <= 0 }">
      <div
        class="place-order__button"
        :class="{ disabled: !cart_qty_total || cart_qty_total <= 0 }"
        @click.stop.prevent="handlePlaceOrder"
      >
        <span @click.stop.prevent="handlePlaceOrder">PLACE ORDER</span>
      </div>
    </div> -->
    <!-- <p>shopping bag</p> <router-link :to="{name: 'vmColaPayment'}">payment</router-link> -->

    <FooterCart
      :cart-showed="true"
      :total-price="total_price"
      @reset="handleReset"
      @show-cart="show_cart = true"
      @checkout="() => handlePlaceOrder()"
    />

    <ModalPointList
      v-if="modal_point_list"
      :point-list="user_point_list"
      :selected-point-index="active_point"
      @selected-point="handleSelectedPoint"
      @close="(is_apply) => handleClosePointList(is_apply)"
    />

    <ModalApplyPoint v-if="modal_apply_point" @close="modal_apply_point = false" />

    <ModalConfirmRemoveProduct
      v-if="modal_confirm_remove_product"
      :index-product="remove_product_index"
      @close="
        modal_confirm_remove_product = false;
        remove_product_index = -1;
      "
      @delete-product="removeProduct"
    />

    <ModalConfirmCancelPoint
      v-if="modal_confirm_cancel_point"
      @close="modal_confirm_cancel_point = false"
      @cancel-point="handleCancelPoint"
    />

    <ModalCancelShopping v-if="cancelShopping" @close="$emit('close-shopping-modal')" @logout="$emit('logout')" />

    <ModalPayment
      v-if="modal_payment"
      :order-response="place_order_response"
      @confirm="modal_cancel_payment = true"
      @close="modal_payment = false"
    />

    <ModalCancelPayment
      v-if="modal_cancel_payment"
      @close="modal_cancel_payment = false"
      @submit="backAndCancelPayment"
    />

    <ModalPointApplied v-if="is_point_applied" :selected-point="selected_point" @close="is_point_applied = false" />

    <ModalExceedMaxProducts v-if="modal_exceed_max_products" @close="modal_exceed_max_products = false" />
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';
import ls from '@/components/helpers/local-storage';
import autotimeout from '@/mixins/auto-timeout';
import { get } from 'lodash';

const Config = require('~/config/default.env').default;
const moment = require('moment');

export default {
  name: 'vmColaShoppingBag',
  components: {
    ModalPointList: () => import('@/components/vm-cola/snippets/modal/modal-point-list.vue'),
    ModalApplyPoint: () => import('@/components/vm-cola/snippets/modal/modal-apply-point.vue'),
    ModalConfirmRemoveProduct: () => import('@/components/vm-cola/snippets/modal/modal-confirm-remove-product.vue'),
    ModalConfirmCancelPoint: () => import('@/components/vm-cola/snippets/modal/modal-confirm-remove-points.vue'),
    ModalCancelShopping: () => import('@/components/vm-cola/snippets/modal/modal-cancel-shopping.vue'),
    ModalPayment: () => import('@/components/vm-cola/snippets/modal/modal-payment.vue'),
    ModalCancelPayment: () => import('@/components/vm-cola/snippets/modal/modal-cancel-payment.vue'),
    ModalPointApplied: () => import('@/components/vm-cola/snippets/modal/modal-point-applied.vue'),

    ModalExceedMaxProducts: () => import('@/components/vm-cola/snippets/modal/modal-exceed-max-products'),

    FooterCart: () => import('@/components/vm-cola/snippets/footer/cart'),
  },

  filters: {
    thousandSeparator(value) {
      const res = (value || 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
      return res;
    },
  },
  mixins: [helpers, autotimeout],

  props: {
    cancelShopping: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      page_initial: true,

      qrGenTimer: null,
      getTokenTimer: null,
      loading_token: false,

      is_register: true,
      is_login: false,

      modal_point_list: false,
      modal_apply_point: false,
      modal_confirm_remove_product: false,
      modal_confirm_cancel_point: false,
      modal_payment: false,

      modal_exceed_max_products: false,

      active_point: -1,
      remove_product_index: -1,
      place_order_response: {},

      is_guest: false,
      modal_cancel_payment: false,

      is_point_applied: false,
    };
  },
  computed: {
    eligible_redeem_soco_point() {
      return this.user_points >= 100;
    },
    orderCancelledLoading() {
      return this.$store.state.vmCola.order_cancelled_loading;
    },
    eligibleToProceed() {
      return this.carts.findIndex((res) => 'is_available' in res.raw.detail && !res.raw.detail.is_available) > -1
        ? false
        : true;
    },
    carts: {
      get: function () {
        let result = [];

        if (this.$store.state.vmCola.footer_cart && this.$store.state.vmCola.footer_cart.length) {
          const data = this.$store.state.vmCola.footer_cart;
          if (data && data.length) {
            ls.set('cart', JSON.stringify(data));
          }
          result = data;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.page_initial = false;
        }

        return result;
      },
      set: function (value) {
        ls.set('cart', JSON.stringify(value));
        return value;
      },
    },
    total_items() {
      return (this.carts?.length && this.carts.reduce((acc, curr) => (acc += curr.qty), 0)) || 0;
    },
    cart_qty_total() {
      return this.$store.state.vmCola.cart_qty;
    },
    point_applied() {
      return this.$store.state.vmCola.point_applied || 0;
    },
    total_price() {
      if (this.carts && this.carts.length) {
        let result = 0;
        const data = this.carts;

        for (let i = 0; i < data.length; i++) {
          result += Number(this.convertPriceNumber(data[i].raw.detail) * data[i].qty);
        }

        if (this.point_applied && this.point_applied > 0) {
          result -= this.point_applied;
        }

        return result;
      } else {
        return 0;
      }
    },

    QRcode() {
      return this.$store.state.QRcode.value || '';
    },

    user() {
      return this.$store.state.QRcode.user;
    },
    user_points() {
      return (this.user && this.user.points && this.user.points.count) || 0;
    },
    user_point_list() {
      return this.$store.state.auth.userPointList || [];
    },
    routeName() {
      return this.$route.name;
    },
    selected_point() {
      return this.$store.state.QRcode.selected_point;
    },
    latest_cart_id() {
      return this.$store.state.QRcode.latest_cart_id;
    },
    auth_token() {
      return this.$store.state.auth.authToken || null;
    },
    product_list() {
      return this.$store.state.vmCola.product_list;
    },
    isOrderCreated() {
      return this.$store.state.QRcode.is_order_created;
    },
  },

  watch: {
    point_applied: {
      handler() {
        if (this.point_applied && this.point_applied > 0) {
          this.is_point_applied = true;

          setTimeout(() => {
            this.is_point_applied = false;
          }, 10000);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.$store.commit('SET_GLOBAL_LOADING', false);
    this.is_point_applied = false;
  },

  async mounted() {
    // this.resetInactivityTimer();
    this.$store.commit('SET_GLOBAL_LOADING', true);
    this.is_guest = await ls.get('is_guest');

    setTimeout(async () => {
      await this.$store.dispatch('fetchCurrentUserCart', this);
      this.$store.commit('SET_GLOBAL_LOADING', false);
    }, 1000);
  },

  methods: {
    async handleReset(qty_total) {
      if (qty_total <= 0) {
        return;
      }

      await this.removeProducts();

      try {
        Promise.all([ls.remove('cart'), this.$store.dispatch('resetCart', 0), this.$store.commit('footer_cart', [])]);
      } catch (error) {
        console.log(error);
      }
    },

    handleClosePointList(is_apply) {
      this.modal_point_list = false;

      if (is_apply) {
        this.modal_apply_point = true;
      }
    },
    handleSelectedPoint({ point, index }) {
      this.active_point = index;
      this.$store.commit('SET_SELECTED_POINT', point);
    },

    async showRedeemPointList() {
      if (this.orderCancelledLoading) {
        return;
      }

      if (moment().diff(this.user.created_at, 'days') < 30) {
        this.$toasted.global.error('Soco Point dapat diredeem jika akun sudah lebih dari 30 hari');
        return;
      }

      await this.$store.dispatch('getPointListByUser', { total_amount: this.total_price });

      this.modal_point_list = this.user_point_list?.length || false;
    },
    clickPointAction() {},
    urlDetail(item) {
      let id;
      let slug;
      if (item.raw && item.raw.detail) {
        id = item.raw.detail.id;
        slug = item.raw.detail.slug;
      } else {
        id = item.id;
        slug = `${id}-${this.$options.filters.slug(item.name)}`;
      }
      if ('product' in item && item.product && item.product.slug) {
        slug = item.product.slug;
      }

      return `/product/${slug}`;
    },
    setAttributeName(item) {
      if (item.variant) {
        if (item.variant.indexOf('non_specify') == -1) {
          return item.variant;
        }
      } else {
        if (item.raw.is_pack && item.raw.pack_detail && item.raw.pack_detail.length) {
          const font_1 = `font-family:'brandontext-bold',sans-serif;`;
          const font_2 = `font-family:'Lato',sans-serif;`;
          const color_1 = `color:#000;`;
          const color_2 = `color:#8C8582;`;
          const color_3 = `color:#DA2A52;`;
          const array = item.raw.pack_detail;
          let result = `<div style="font-size:12px;margin-top:20px;${font_1}${color_3}">BUNDLE PACK:</div>`;

          result += `<ul style="font-size:12px;list-style:none;padding:0;margin:0;${font_2}">`;

          for (let i = 0; i < array.length; i++) {
            if (array[i].detail) {
              result += `<li><div style="${color_1}">${array[i].detail.name}</div><div style="${color_2}">${
                this.convertCombination(array[i].detail.combination, true)
                  ? this.convertCombination(array[i].detail.combination, true) + ` - `
                  : ``
              }${Number(array[i].quantity)}Pcs</div></li>`;
            }
          }

          result += `</ul>`;

          return result;
        } else {
          return this.convertCombination(item.raw.detail.combination);
        }
      }
    },
    convertCombination(combination, is_pack = false) {
      if (combination) {
        let combination_name = '';

        const attributes = combination.attributes ? combination.attributes : {};
        const group = Object.keys(attributes);

        for (let i = 0; i < group.length; i++) {
          if (group.length > 1) {
            const attr_name_1 =
              group[0].indexOf('non_specify') == -1
                ? group[0].toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                    return a.toUpperCase();
                  })
                : '';
            const attr_name_2 =
              group[1].indexOf('non_specify') == -1
                ? group[1].toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                    return a.toUpperCase();
                  })
                : '';
            const attr_1 = attr_name_1 ? (!is_pack ? attr_name_1 + ': ' : '') + attributes[group[0]].name + ', ' : '';
            const attr_2 = attr_name_2 ? (!is_pack ? attr_name_2 + ': ' : '') + attributes[group[1]].name : '';
            combination_name = attr_1 + attr_2;
          } else {
            if (group[0] && group[0].indexOf('non_specify') == -1) {
              const attr_name = group[0].toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
              });
              combination_name = (!is_pack ? attr_name + ': ' : '') + attributes[group[0]].name;
            }
          }
        }

        return combination_name;
      }
    },
    convertPrice(data) {
      let el = ``;
      const font_1 = `font-family:'brandontext-regular',sans-serif;`;
      const font_2 = `font-family:'brandontext-bold',sans-serif;`;
      const color_1 = `color:#000;`;
      const color_2 = `color:#8C8582;`;
      const color_3 = `color:#DA2A52;`;
      const margin = `margin:0 5px;`;

      // const is_discount = false;
      // const discount = data.combination.price_after_discount;
      if (data.combination.price_after_discount) {
        if (
          data.combination.price_after_discount &&
          data.combination.deduction_type &&
          data.combination.price &&
          data.combination.price_after_discount != data.combination.price
        ) {
          el += `<span style="${font_1}${color_2}text-decoration:line-through;">${this.$options.filters.currencyRp(
            data.combination.price
          )}</span>`;
          el += `<span style="${font_2}${color_3}${margin}">${this.$options.filters.currencyRp(
            this.convertPriceNumber(data)
          )}</span>`;
          if (data.combination.deduction_type == 'percentage') {
            el += `<span style="${font_1}${color_3}">[-${data.combination.deduction_percentage}%]</span>`;
          } else if (data.combination.deduction_type == 'amount') {
            el += `<span style="${font_1}${color_3}">[-${this.$options.filters.currencyRp(
              data.combination.deduction_amount
            )}]</span>`;
          }
        } else {
          el = `<span style="${font_2}${color_1}">${this.$options.filters.currencyRp(
            this.convertPriceNumber(data)
          )}</span>`;
        }
      } else {
        el = `<span style="${font_2}${color_1}">${this.$options.filters.currencyRp(
          this.convertPriceNumber(data)
        )}</span>`;
      }

      return el;
    },
    convertPriceNumber(item) {
      return item.combination.price_after_discount ? item.combination.price_after_discount : item.combination.price;
    },
    pickImage(item) {
      let prdImg = item.images && item.images.length && item.images[0];

      if (!prdImg) {
        prdImg = item.image && item.image;
        Object.assign(item, { images: [prdImg] });
      }

      const defaultCombImg =
        item.default_combination && item.default_combination.images && item.default_combination.images.length;
      if (prdImg || defaultCombImg) {
        let imageCover;
        if (prdImg && item.images.findIndex((res) => res.is_cover) > -1) {
          imageCover = item.images.find((res) => res.is_cover).url;
        }
        if (
          defaultCombImg &&
          item.default_combination.images[0] &&
          item.default_combination.images.findIndex((res) => res && 'is_cover' in res && res.is_cover) > -1
        ) {
          imageCover = item.default_combination.images.find((res) => res.is_cover).url;
        }
        if (imageCover) {
          return imageCover;
        }
      }
      return 'https://placehold.it/100x100';
    },
    brokenImage(e) {
      e.target.src = 'https://placehold.it/100x100';
    },
    async adjustQty(i, type) {
      if (this.total_items >= 4 && type !== 'minus') {
        // this.$toasted.global.error('You cannot add more than 4 items.');
        this.modal_exceed_max_products = true;
        return;
      }

      if (type === 'plus' && this.product_list?.length) {
        const temp = this.carts[i];
        const prod_qty = temp.qty;
        const vm_product = get(temp, 'raw.detail.combination', null);
        if (vm_product && vm_product.stores?.length) {
          const store_id = await Cookies.get('store_location_id');
          const vm_product_stock = vm_product.stores.find((s) => s.id === store_id);

          if (vm_product_stock && vm_product_stock.stock <= prod_qty) {
            this.$toasted.global.show('The product exceeds the limit.');
            return;
          }
        }
      }

      const temp = this.carts[i];
      const product = {
        product: {
          id: temp.raw.id,
          combination_id: temp.raw.combination_id,
          quantity: 1,
        },
        action: type,
      };

      this.$store.commit('SET_GLOBAL_LOADING', true);
      await this.$store.dispatch('updateCart', { product, index: i, context: this }).finally(() => {
        this.$store.commit('SET_GLOBAL_LOADING', false);
      });
    },
    confirmRemoveCart(i) {
      this.modal_confirm_remove_product = true;
      this.remove_product_index = i;
    },
    async removeProduct(i) {
      const temp = this.carts[i];
      const product = {
        product: {
          id: temp.raw.id,
          combination_id: temp.raw.combination_id,
          quantity: temp.qty,
        },
        action: 'delete',
      };

      this.$store.commit('SET_GLOBAL_LOADING', true);
      await this.$store
        .dispatch('updateCart', { product, index: i, context: this })
        .finally(() => {})
        .then(() => {
          this.modal_confirm_remove_product = false;
        })
        .finally(() => {
          this.$store.commit('SET_GLOBAL_LOADING', false);
        });
    },
    confirmCancelPoint() {
      this.modal_confirm_cancel_point = true;
    },
    handleCancelPoint() {
      this.$store.commit('SET_GLOBAL_LOADING', true);
      this.$store
        .dispatch('cancelPoints', {
          token: this.auth_token,
          cart_id: this.latest_cart_id,
          points: (this.point_applied && this.point_applied / 100) || 0,
          context: this,
        })
        .finally(() => {
          this.$store.commit('SET_SELECTED_POINT', 0);
          this.$store.commit('point_applied', 0);
          this.$store.commit('SET_GLOBAL_LOADING', false);
          this.modal_confirm_cancel_point = false;
        });
    },

    async handlePlaceOrder() {
      if (this.orderCancelledLoading) {
        return;
      }

      if (this.total_items > 4) {
        // this.$toasted.global.error('You cannot add more than 4 items.');
        this.modal_exceed_max_products = true;
        return;
      }

      if (!this.cart_qty_total || this.cart_qty_total <= 0) {
        return;
      }

      this.$store.commit('SET_GLOBAL_LOADING', true);

      this.$store
        .dispatch('placeOrder', this)
        .then((res) => {
          this.place_order_response = res?.data?.data;
          return res;
        })
        .then((order) => {
          if (order) {
            this.modal_payment = true;
            return;
          }
        })
        .finally(() => {
          this.$store.commit('SET_GLOBAL_LOADING', false);
        });
    },

    async backAndCancelPayment(without_confirmation) {
      if (without_confirmation) {
        this.modal_cancel_payment = true;
      }

      this.$store.commit('SET_GLOBAL_LOADING', true);

      const url = Config.MS_CART_API_URL + '/carts/reorder';
      const [loc, token] = await Promise.all([Cookies.get('store_location_id'), Cookies.get('token')]);

      if (loc) {
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };

        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const is_guest = await ls.get('is_guest');

        if (is_guest) {
          headers['is-guest'] = true;
          headers['guest-checkout'] = true;
        }

        try {
          const response = await axios.post(url, { cart_id: this.latest_cart_id }, { headers: headers });
          const promises = [this.abandonCart(this.latest_cart_id)];

          if (!is_guest) {
            promises.push(this.cancelOrderManual());
          }

          Promise.all(promises);

          if (response.status === 200) {
            await this.$store.commit('SET_CART_ID', response.data.data._id);

            if (is_guest) {
              await ls.set('guest_cart_id', response.data.data._id);
            }

            this.$store.dispatch('fetchCurrentUserCart', this);

            this.modal_cancel_payment = false;
            this.modal_payment = false;
            this.$store.commit('SET_GLOBAL_LOADING', false);
          }
        } catch (e) {
          this.$store.commit('SET_GLOBAL_LOADING', false);
        }
      }
    },
    async cancelOrderManual() {
      if (this.isOrderCreated && '_id' in this.isOrderCreated) {
        try {
          this.$store.commit('SET_ORDER_CANCELLED_LOADING', true);

          const { _id: order_id, reference_code } = this.isOrderCreated;
          const loc = await Cookies.get('store_location_id');

          const headers = {
            'SOC-PLATFORM': 'vending-machine',
            store_id: loc,
          };
          const authToken = this.$store.state.QRcode.authToken;
          if (authToken) {
            headers['Authorization'] = `Bearer ${authToken}`;
          } else {
            const token = await Cookies.get('token');
            headers['Authorization'] = `Bearer ${token}`;
          }
          const is_guest = await ls.get('is_guest');
          if (is_guest) {
            headers['is-guest'] = true;
            headers['guest-checkout'] = true;
          }

          await axios.put(
            `${Config.MS_ORDER_API_URL}/order/${order_id}/cancelled`,
            {
              cancellation_reason: 'Cancel vending machine',
              reference_code,
            },
            {
              headers: headers,
            }
          );
          this.$store.commit('SET_ORDER_CANCELLED_LOADING', false);
        } catch (error) {
          console.error(error);
          this.$store.commit('SET_ORDER_CANCELLED_LOADING', false);
        }
      }
    },
    async abandonCart(cart_id) {
      try {
        const loc = await Cookies.get('store_location_id');
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
          store_id: loc,
        };
        const authToken = this.$store.state.QRcode.authToken;
        if (authToken) {
          headers['Authorization'] = `Bearer ${authToken}`;
        } else {
          const token = await Cookies.get('token');
          headers['Authorization'] = `Bearer ${token}`;
        }
        const is_guest = await ls.get('is_guest');
        if (is_guest) {
          headers['is-guest'] = true;
          headers['guest-checkout'] = true;
        }
        await axios.put(
          `${Config.MS_CART_API_URL}/user/me/carts/status`,
          {
            cart_id,
            status: 'abandoned',
          },
          {
            headers: headers,
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'shopping-bag';
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
