export default {
  props: {
    timeoutDurationProp: {
      type: Number,
      default: 120000,
    },
  },
  data() {
    return {
      timeoutDuration: this.timeoutDurationProp,
      inactivityTimer: null,
      tickTimer: null,

      showTick: false,
      tick: 0,
    };
  },
  computed: {
    userState() {
      return this.$store.state.QRcode.user;
    },
    isOrderCreated() {
      return this.$store.state.QRcode.is_order_created;
    },
  },
  mounted() {
    if (this.userState) {
      this.timeoutDuration = 120000;
    }

    if (
      this.$route.name &&
      [
        'vmColaPaymentFailed',
        'vmColaPaymentSuccessFailed',
        'vmColaPaymentSuccess',
        'SocoBeautyStarFinish',
        'vendingMachinePimplePopGameFinish',
      ].includes(this.$route.name)
    ) {
      this.timeoutDuration = 30000; // EC-5123
    }

    this.tick = 0;
    this.resetInactivityTimer();
    window.addEventListener('mousemove', this.resetInactivityTimer);
    window.addEventListener('keydown', this.resetInactivityTimer);
    window.addEventListener('touchstart', this.resetInactivityTimer);
    window.addEventListener('scroll', this.resetInactivityTimer);
  },
  beforeDestroy() {
    clearTimeout(this.inactivityTimer);
    clearInterval(this.tickTimer);
    this.tick = 0;

    window.removeEventListener('mousemove', this.resetInactivityTimer);
    window.removeEventListener('keydown', this.resetInactivityTimer);
    window.removeEventListener('touchstart', this.resetInactivityTimer);
    window.removeEventListener('scroll', this.resetInactivityTimer);
  },
  methods: {
    resetInactivityTimer() {
      this.tick = 0;
      clearTimeout(this.inactivityTimer);
      clearInterval(this.tickTimer);

      const vm = this;
      this.inactivityTimer = setTimeout(() => {
        this.handleTimeoutAction(vm);
      }, this.timeoutDuration);

      this.tickTimer = setInterval(() => {
        this.tick++;

        if (this.showTick) {
          console.log(
            `${this.$route.path} - timeout on ${this.timeoutDuration / 1000} secs - countdown ${this.tick} sec`
          );
        }
      }, 1000);
    },
    handleTimeoutAction(context) {
      this.resetInactivityTimer();
      if (Cookies.get('vending_machine_device') == 'true' && context.$route.name === 'vendingMachineHome') {
        return;
      }
      if (context.$route.path.includes('/soco-vending-machine-event/')) {
        context.$router.push('/soco-vending-machine-event/home');
        return;
      }
      try {
        if ('modal_payment' in context && context.modal_payment) {
          context.modal_payment = false;
          context.backAndCancelPayment(false);
        } else {
          context.cancelOrder(true, '/vmcola/', false, this.isOrderCreated);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
